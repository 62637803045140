import request from '@/utils/request'

// !读取论坛热榜
export function getHot(params) {
  return request({
    url: '/Forum/GetPublishAllForumHot',
    method: 'get',
    params
  })
}
// !读取论坛实时
export function getReal(params) {
  return request({
    url: '/Forum/GetPublishAllForumReal',
    method: 'get',
    params
  })
}
// !保存论坛
export function saveForum(data) {
  return request({
    url: '/Forum/SaveForum',
    method: 'post',
	data
  })
}
// !获取当前用户的草稿箱
export function getDraft(params) {
  return request({
    url: '/Forum/GetSaveForum',
    method: 'get',
	params
  })
}
// !发布论坛
export function publishForum(data) {
  return request({
    url: '/Forum/PublishForum',
    method: 'post',
	data
  })
}
// !获取当前用户收藏文章
export function getCollect(params) {
  return request({
    url: '/Forum/GetCollectForum',
    method: 'get',
	params
  })
}
// !收藏文章
export function collectArticle(data) {
  return request({
    url: '/Forum/AddCollectForum',
    method: 'post',
	data
  })
}
// !取消收藏文章
export function collectCancelArticle(data) {
  return request({
    url: '/Forum/CancelCollectForum',
    method: 'post',
	data
  })
}
// !点赞文章
export function likeArticle(data) {
  return request({
    url: '/Forum/AddLikeForum',
    method: 'post',
	data
  })
}
// !取消点赞文章
export function likeCancelArticle(data) {
  return request({
    url: '/Forum/CancelLikeForum',
    method: 'post',
	data
  })
}
// !获取文章详细信息
export function getDetails(params) {
  return request({
    url: '/Forum/GetForumInfo',
    method: 'get',
	params
  })
}
// !获取产品类型
export function getProduct(params) {
  return request({
    url: '/Home/GetSelectModule',
    method: 'get',
	params
  })
}
// !删除文章
export function delArticle(params) {
  return request({
    url: '/Forum/DeleteForum',
    method: 'get',
	params
  })
}
// !编辑文章
export function editArticle(data) {
  return request({
    url: '/Forum/UpdateForum',
    method: 'post',
	data
  })
}
// !获取我的发帖
export function getPost(params) {
  return request({
    url: '/Forum/GetUserPublishForum',
    method: 'get',
	params
  })
}
// !获取评论列表
export function getComment(params) {
  return request({
    url: '/Forum/GetForumComment',
    method: 'get',
	params
  })
}
// !评论文章
export function addComment(data) {
  return request({
    url: '/Forum/AddForumComment',
    method: 'post',
	data
  })
}
// !删除评论
export function delComment(data) {
  return request({
    url: '/Forum/DeleteForumComment',
    method: 'post',
	data
  })
}
// !获取我的点赞
export function getUserLike(params) {
  return request({
    url: '/Forum/GetUserLikeForum',
    method: 'get',
	params
  })
}
// !获取我的评论
export function getUserComment(params) {
  return request({
    url: '/Forum/GetUserCommentForum',
    method: 'get',
	params
  })
}
// !pc获取热榜
export function getHotPc(params) {
  return request({
    url: '/Forum/GetPublishAllForumHotPC',
    method: 'get',
	params
  })
}
// !app获取热榜
export function getHotApp(params) {
  return request({
    url: '/Forum/GetPublishAllForumHotApp',
    method: 'get',
	params
  })
}
// !pc获取实时
export function getRealPc(params) {
  return request({
    url: '/Forum/GetPublishAllForumRealPC',
    method: 'get',
	params
  })
}
// !app获取实时
export function getRealApp(params) {
  return request({
    url: '/Forum/GetPublishAllForumRealApp',
    method: 'get',
	params
  })
}
// !签到
export function signIn(data) {
  return request({
    url: '/Forum/Signin',
    method: 'post',
	data
  })
}
// !获取签到
export function getSignInfo(params) {
  return request({
    url: '/Forum/GetUserSignIn',
    method: 'get',
	params
  })
}
// !打赏文章
export function award(params) {
  return request({
    url: '/Forum/Givereward',
    method: 'get',
	params
  })
}
// !获取公告
export function getAnnounce(data) {
  return request({
    url: '/Notice/SystemNotice',
    method: 'post',
	data
  })
}
// !举报文章
export function report(data) {
  return request({
    url: '/Forum/AddReportContentForum',
    method: 'post',
	data
  })
}
// !点赞评论
export function likeComment(data) {
  return request({
    url: '/Forum/AddForumCommentLike',
    method: 'post',
	data
  })
}
// !取消点赞评论
export function likeCancelComment(data) {
  return request({
    url: '/Forum/DeleteForumCommentLike',
    method: 'post',
	data
  })
}
// !举报评论
export function reportComment(data) {
  return request({
    url: '/Forum/AddReportComment',
    method: 'post',
	data
  })
}
// !获取是否签到
export function getIsSign(params) {
  return request({
    url: '/Forum/IsSignin',
    method: 'get',
	params
  })
}
// !保存并发布
export function savePublish(data) {
  return request({
    url: '/Forum/SaveAndPublishForum',
    method: 'post',
	data
  })
}
// !分享字数记录
export function shareNum(data) {
  return request({
    url: '/Forum/AddShareForum',
    method: 'post',
    data
  })
}