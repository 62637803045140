<template>
  <div class="forum_page">
    <div v-loading="loading"></div>
    <div class="merryArea_mobile">
      <van-notice-bar left-icon="volume-o" scrollable :text="announce[0]" />
    </div>
    
    <swiper :banners="banners"></swiper>
    <div class="typeArea">
      <div class="crumb">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div class="content">
        <div class="left">
          <h2>驭剑</h2>
          <ul>
            <li v-for="(item, index) in productList" :class="type == item.value ? 'active3' : ''" @click="navTab(item.value, index)" :key="index">
              {{ item.text }}
            </li>
          </ul>
        </div>

        <div class="center">
          <div class="merry_area">
            <van-notice-bar left-icon="volume-o" scrollable :text="announce[0]" />
          </div>
          <div class="search_area">
            <div class="search_input">
              <a @click="search"><img src="../../assets/PC/forum/search.png" /></a>
              <input type="text" v-model="searchVal" />
            </div>
            <div class="caret_icon" @click="isSlideShow = !isSlideShow">
              <i class="el-icon-caret-top" :class="[isSlideShow ? '' : 'rotate_180']"></i>
              <span>{{ isSlideShow ? "收起" : "展开" }}</span>
            </div>
          </div>
          <ul class="operate_list" v-if="isSlideShow">
            <li>
              <svg-icon icon-class="sign"></svg-icon>
              <svg-icon icon-class="sign_a"></svg-icon>
              <p>签到</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Article')">
              <svg-icon icon-class="write"></svg-icon>
              <svg-icon icon-class="write_a"></svg-icon>
              <p>写文章</p>
            </li>
            <li>
              <svg-icon icon-class="draft"></svg-icon>
              <svg-icon icon-class="draft_a"></svg-icon>
              <p>草稿箱</p>
            </li>
            <li>
              <svg-icon icon-class="collect"></svg-icon>
              <svg-icon icon-class="collect_a"></svg-icon>
              <p>收藏夹</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Post')">
              <svg-icon icon-class="post"></svg-icon>
              <svg-icon icon-class="post_a"></svg-icon>
              <p>我的发帖</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Message')">
              <svg-icon icon-class="message"></svg-icon>
              <svg-icon icon-class="message_a"></svg-icon>
              <p>我的消息</p>
            </li>
          </ul>
          <div class="module_list">
            <div class="nav0-item nav0-item1" ref="nav0ItemPop">
              <p class="flex0" @click="IsNones">
                产品 <i class="el-icon-caret-bottom"></i>
              </p>
              <div class="nav0-item-pop products">
                <el-collapse-transition>
                  <ul v-if="isNone">
                    <li v-for="(item, index) in productList" :key="item.value" class="flex0" :class="type == item.value ? 'active3' : ''" @click="navTab(item.value, index)">
                      {{ item.text }}
                    </li>
                  </ul>
                </el-collapse-transition>
              </div>
            </div>
          </div>

          <div class="tab_nav">
            <div class="nav_item" :class="[tab_current == 0 ? 'active' : '']" @click="tabClick(0)">热榜</div>
            <div class="nav_item" :class="[tab_current == 1 ? 'active' : '']" @click="tabClick(1)">实时</div>
          </div>
          <div class="tab_content">
            <div class="tab_item" v-if="tab_current == 0">
              <div class="rank_content">
                <div class="rank_item" :class="[item.isTop ? 'rank_item_top' : '']" v-for="(item, index) in rankList" :key="index">
                  <img src="@/assets/PC/forum/rank_icon_0.png" class="rank_icon rank_icon_inner" v-if="item.isTop"/>
                  <img src="@/assets/PC/forum/rank_icon_1.png" class="rank_icon" v-else-if="!item.isTop && item.sort == 1"/>
                  <img src="@/assets/PC/forum/rank_icon_2.png" class="rank_icon" v-else-if="!item.isTop && item.sort == 2"/>
                  <img src="@/assets/PC/forum/rank_icon_3.png" class="rank_icon" v-else-if="!item.isTop && item.sort == 3"/>
                  <img src="@/assets/PC/forum/rank_icon_4.png" class="rank_icon rank_icon_inner" v-else-if="!item.isTop && item.sort == 4"/>
                  <img src="@/assets/PC/forum/rank_icon_5.png" class="rank_icon rank_icon_inner" v-else-if="!item.isTop && item.sort == 5"/>
                  <div class="title_area">
                    <h4 @click="toDetails(item.id)">{{ item.title }}</h4>
                  </div>
                  <p class="item_date">发布于{{ item.publishDateString }}</p>
                  <p class="item_desc" @click="toDetails(item.id)">
                    <span class="violet">{{ item.topic ? "#" + item.topic + "#" : ""}}</span>
                    {{ item.introduction }}
                  </p>
                  <div class="item_kind">
                    <a v-for="(industryItem, industryIndex) in item.industryName" :key="item.industry[industryIndex]" >{{ industryItem }}</a>
                    <a v-for="(productItem, productIndex) in item.productName" :key="item.product[productIndex]" >{{ productItem }}</a>
                  </div>
                  <div class="item_end">
                    <div class="item_num">
                      <a @click="like(0, item.id, item.isLike, index)" :class="[item.isLike ? 'active' : '']" title="点赞">
                        <svg-icon icon-class="sword"></svg-icon>
                        <svg-icon icon-class="sword_a"></svg-icon>
                        <span v-if="item.likeCount > 10000">{{ (item.likeCount / 10000).toFixed(1) }}w+</span>
                        <span v-else>{{ item.likeCount }}</span>
                      </a>

                      <a @click="collect(0, item.id, item.isCollect, index)" :class="[item.isCollect ? 'active' : '']" title="收藏">
                        <svg-icon icon-class="shield"></svg-icon>
                        <svg-icon icon-class="shield_a"></svg-icon>
                        <span v-if="item.collectCount > 10000">{{ (item.collectCount / 10000).toFixed(1) }}w+</span>
                        <span v-else>{{ item.collectCount }}</span>
                      </a>

                      <a :class="[item.isComment ? 'active' : '']" title="评论">
                        <svg-icon icon-class="discuss"></svg-icon>
                        <svg-icon icon-class="discuss_a"></svg-icon>
                        <span v-if="item.comment > 10000">{{ (item.comment / 10000).toFixed(1) }}w+</span>
                        <span v-else>{{ item.comment }}</span>
                      </a>

                      <a title="转发">
                        <svg-icon icon-class="share"></svg-icon>
                        <span>{{ item.shareNum }}</span>
                        <ul>
                          <li>
                            <a>
                              <img src="../../assets/PC/forum/icon_copy.png" />
                              <span v-clipboard:copy="item.link" v-clipboard:success="onCopy">复制链接</span>
                            </a>
                          </li>
                          <li>
                            <a>
                              <img src="../../assets/PC/forum/icon_sina.png" />
                              <span @click="toSina(item.title, item.id)">新浪微博</span>
                            </a>
                          </li>
                          <li>
                            <a>
                              <img src="../../assets/PC/forum/icon_wechat.png"/>
                              <span>微信扫一扫</span>
                            </a>
                          </li>
                          <li class="qrCode_rank" :id="'rankCode' + index"></li>
                        </ul>
                      </a>
                    </div>
                    <div class="router_icon" @click="toDetails(item.id)">
                      <img src="../../assets/PC/forum/shield_purple.png" />
                      <i class="el-icon-top-left"></i>
                    </div>
                  </div>
                </div>
                <div class="noData" v-if="noData">
                  <img src="@/assets/PC/profile/listNone.png" alt="" />
                </div>
                <div class="rank_pagination" v-if="rankRecordCount > pageSize">
                  <el-pagination
                    background
                    layout="pager, next"
                    next-text="下一页"
                    :page-size="pageSize"
                    :pager-count="pageCount"
                    :total="rankRecordCount"
                    @current-change="rankPageClick"
                    :current-page="rankParams.page"
                    @next-click="rankPageClick"
                  >
                  </el-pagination>
                </div>
              </div>

              <div v-if="noRank" class="loadList flex0">
                <div class="loadList0" @click="getList()">加载更多</div>
              </div>
              <div v-if="screenWidth < 750 && !noRank && rankList.length > 0" class="tc notDetail">
                <div>
                  没有更多内容了~
                  <span class="violet" @click="scrollTop">返回顶部</span>
                </div>
              </div>
            </div>
            <div class="tab_item" v-else-if="tab_current == 1">
              <div class="real_content">
                <div class="real_item" :class="[item.isTop ? 'real_item_top' : '']" v-for="(item, index) in realList" :key="index">
                  <img src="@/assets/PC/forum/rank_icon_0.png" class="real_icon real_icon_inner" v-if="item.isTop"/>
                  <div class="title_area">
                    <h4 @click="toDetails(item.id)">{{ item.title }}</h4>
                  </div>
                  <p class="item_date">发布于{{ item.publishDateString }}</p>
                  <p class="item_desc" @click="toDetails(item.id)">
                    <span class="violet">{{item.topic ? "#" + item.topic + "#" : ""}}</span>
                    {{ item.introduction }}
                  </p>
                  <div class="item_kind">
                    <a v-for="(industryItem, industryIndex) in item.industryName" :data-id="item.industry[industryIndex]">{{ industryItem }}</a>
                    <a v-for="(productItem, productIndex) in item.productName" :data-id="item.product[productIndex]" >{{ productItem }}</a>
                  </div>
                  <div class="item_end">
                    <div class="item_num">
                      <a @click="like(1, item.id, item.isLike, index)" :class="[item.isLike ? 'active' : '']" title="点赞">
                        <svg-icon icon-class="sword"></svg-icon>
                        <svg-icon icon-class="sword_a"></svg-icon>
                        <span>{{ item.likeCount }}</span>
                      </a>
                      <a @click="collect(1, item.id, item.isCollect, index)" :class="[item.isCollect ? 'active' : '']" title="收藏">
                        <svg-icon icon-class="shield"></svg-icon>
                        <svg-icon icon-class="shield_a"></svg-icon>
                        <span>{{ item.collectCount }}</span>
                      </a>
                      <a :class="[item.isComment ? 'active' : '']" title="评论">
                        <svg-icon icon-class="discuss"></svg-icon>
                        <svg-icon icon-class="discuss_a"></svg-icon>
                        <span>{{ item.comment }}</span>
                      </a>
                      <a title="转发">
                        <svg-icon icon-class="share"></svg-icon>
                        <span>{{ item.shareNum }}</span>
                        <ul>
                          <li>
                            <a>
                              <img
                                src="../../assets/PC/forum/icon_copy.png"
                                alt=""
                              />
                              <span
                                v-clipboard:copy="item.link"
                                v-clipboard:success="onCopy"
                                >复制链接</span
                              >
                            </a>
                          </li>
                          <li>
                            <a>
                              <img
                                src="../../assets/PC/forum/icon_sina.png"
                                alt=""
                              />
                              <span @click="toSina(item.title, item.id)"
                                >新浪微博</span
                              >
                            </a>
                          </li>
                          <li>
                            <a>
                              <img
                                src="../../assets/PC/forum/icon_wechat.png"
                                alt=""
                              />
                              <span>微信扫一扫</span>
                            </a>
                          </li>
                          <li class="qrCode_real" :id="'realCode' + index"></li>
                        </ul>
                      </a>
                    </div>
                    <div class="router_icon" @click="toDetails(item.id)">
                      <img src="../../assets/PC/forum/shield_purple.png" />
                      <i class="el-icon-top-left"></i>
                    </div>
                  </div>
                </div>
                <div class="noData" v-if="noData">
                  <img src="@/assets/PC/profile/listNone.png" alt="" />
                </div>
                <div class="real_pagination" v-if="realRecordCount > pageSize">
                  <el-pagination
                    background
                    layout="pager, next"
                    next-text="下一页"
                    :page-size="pageSize"
                    :pager-count="pageCount"
                    :total="realRecordCount"
                    @current-change="realPageClick"
                    :current-page="realParams.page"
                    @next-click="realPageClick"
                  >
                  </el-pagination>
                </div>
              </div>

              <div v-if="noReal" class="loadList flex0">
                <div class="loadList0" @click="getList()">加载更多</div>
              </div>
              <div v-if="screenWidth < 750 && !noReal && realList.length > 0" class="tc notDetail">
                <div>
                  没有更多内容了~
                  <span class="violet" @click="scrollTop">返回顶部</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="search_area">
            <a @click="search">
              <svg-icon icon-class="search"></svg-icon>
            </a>
            <input type="text" v-model="searchVal" @keyup.enter="search" />
          </div>
          <ul class="operate_list">
            <li @click="signShow" :class="[current == 0 ? 'current' : '']">
              <svg-icon icon-class="sign"></svg-icon>
              <svg-icon icon-class="sign_a"></svg-icon>
              <p>签到</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Article')">
              <svg-icon icon-class="write"></svg-icon>
              <svg-icon icon-class="write_a"></svg-icon>
              <p>写文章</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Draft')">
              <svg-icon icon-class="draft"></svg-icon>
              <svg-icon icon-class="draft_a"></svg-icon>
              <p>草稿箱</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Collect')">
              <svg-icon icon-class="collect"></svg-icon>
              <svg-icon icon-class="collect_a"></svg-icon>
              <p>收藏夹</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Post')">
              <svg-icon icon-class="post"></svg-icon>
              <svg-icon icon-class="post_a"></svg-icon>
              <p>我的发帖</p>
            </li>
            <li @click="toSec('/Home/ForumSec/Message')">
              <svg-icon icon-class="message"></svg-icon>
              <svg-icon icon-class="message_a"></svg-icon>
              <p>我的消息</p>
            </li>
          </ul>
          <div style="position: relative">
            <i class="el-icon-circle-close cur co-fff" @click="isAdverShow = false" style="position: absolute;top: 10px;right: 10px;z-index: 1;font-size: 16px"></i>
            <div class="adver_area cur" v-if="isAdverShow" @click="keenMind">
              <p>活动中心</p>
              <div></div>
              <p>线上/线下热门活动</p>
            </div>
          </div>
          
          <div class="end_btn">
            <a @click="toAgreeMent">论坛协议</a>
            <a href="https://www.aveva.com/en/legal/privacy-policy/" target="_blank" >隐私政策</a>
          </div>
          <div class="co-999" style="margin-bottom: 25px">
            联系我们：<br>No-Reply@avevacommunity.com
          </div>
        </div>
      </div>
    </div>
    <div class="signPop_area" v-if="isSign && isPc">
      <i class="el-icon-circle-close" @click="signHide"></i>
      <p>
        已连续签到&nbsp;<span>{{ signDate }}</span
        >&nbsp;天
      </p>
      <div>
        <div>
          <div>
            <div
              :style="{
                width:
                  signDate == 7
                    ? 0
                    : (signDate == 0 ? 100 : 94 - (signDate - 1) * 15) + '%',
              }"
            ></div>
          </div>
          <div
            :style="{
              left: signDate == 0 ? 0 : 4 + (signDate - 1) * 14.5 + '%',
            }"
          >
            <p>连续签到7天可领取双倍金币奖励</p>
            <img src="@/assets/PC/forum/gift.png" alt="" />
          </div>
        </div>
        <div>
          <p>1天</p>
          <p>2天</p>
          <p>3天</p>
          <p>4天</p>
          <p>5天</p>
          <p>6天</p>
          <p>7天</p>
        </div>
      </div>
      <button @click="signClick" :class="[isSigned ? 'unClickable' : '']">
        {{ isSigned ? "已签到" : "立即签到" }}
      </button>
    </div>

    <div class="pop_mask" v-if="isSign && !isPc">
      <div class="signPopArea_mobile">
        <i class="el-icon-circle-close" @click="signHide"></i>
        <p>
          已连续签到&nbsp;<span>{{ signDate }}</span
          >&nbsp;天
        </p>
        <div>
          <div>
            <div>
              <div
                :style="{
                  width:
                    signDate == 7
                      ? 0
                      : (signDate == 0 ? 100 : 94 - (signDate - 1) * 15) + '%',
                }"
              ></div>
            </div>
            <div
              :style="{
                left: signDate == 0 ? 0 : 4 + (signDate - 1) * 14.5 + '%',
              }"
            >
              <p>连续签到7天可<br />领取双倍金币奖励</p>
              <img src="@/assets/PC/forum/gift.png" alt="" />
            </div>
          </div>
          <div>
            <p>1天</p>
            <p>2天</p>
            <p>3天</p>
            <p>4天</p>
            <p>5天</p>
            <p>6天</p>
            <p>7天</p>
          </div>
        </div>
        <button @click="signClick" :class="[isSigned ? 'unClickable' : '']">
          {{ isSigned ? "已签到" : "立即签到" }}
        </button>
      </div>
    </div>

    <div class="sign_btn" v-if="!isPc && !isSigned" @click="signShow">
      <svg-icon icon-class="sign_a"></svg-icon>
      <p>签到</p>
    </div>
  </div>
</template>

<script>
import { GetBanner } from "@/api/Home.js";
import swiper from "@/components/Swiper.vue";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import * as forum from "../../api/forum.js";
import QRCode from "qrcodejs2";
import { debounce, GetWxShares } from "@/utils/common";
import { dataState } from "@/api/all.js";
import { ApiToken } from '@/utils/ApiToken'

export default {
  name: "forum",
  data() {
    return {
      loading: true,                                         // !页面加载loading
      refer: [{ name: "首页", href: "/" }, { name: "驭剑" }], // !面包屑
      screenWidth: document.body.clientWidth,                // !窗口宽度
      isSigned: true,                                        // !是否签到
      isNotice: false,
      banners: [],                                           // !banner图
      productList: [{ text: "全部", value: 0 }],             // !Pc 左侧产品
      productList_mobile: [{ text: "全部", value: 0 }],      // !移动端 下拉产品
      type: 0,                                               // !左侧选择
      current: 1,                                            // !右侧选择
      isNone: false,                                         // !产品是否展开
      tab_current: 0,                                        // !热榜、实时榜 切换
      noData: false,                                         // !无数据
      Ismust: false,                                         // !点击加载更多
      noRank: true,                                          // !热榜加载按钮
      noReal: true,                                          // !实时加载按钮
      isPc: true,                                            // !是否是PC端
      rankParams: {
        page: 1,
        limit: 5,
        productType: 0,
        title: "",
      },                                                     // !热榜
      realParams: {
        page: 1,
        limit: 5,
        productType: 0,
        title: "",
      },                                                     // !实时
      rankList: [],                                          // !热榜数组
      realList: [],                                          // !实时数组
      isAdverShow: true,
      searchVal: "",
      announce: [],
      currentPage: 1,
      pageSize: 5,
      pageCount: 5,
      rankRecordCount: 0,
      realRecordCount: 0,
      isSlideShow: false,
      isInitial: true,
      isSign: false,
      signDate: 0,
    };
  },
  created() {
    if (this.screenWidth < 768) {
      this.isPc = false;
    } else {
      this.isPc = true;
    }

    if (this.$route.query.initialType == "sign") {
      this.isSign = true;
    }
  },
  mounted() {
    const share = {
      title: this.$route.meta.title,
      desc: ''
    }
    GetWxShares(share);
    if (this.$route.query.code) {
      this.shareNum(this.$route.query.id);
    }
    this.initial();
    // !获取banner图
    GetBanner().then((res) => {
      const windowWidth = document.documentElement.clientWidth;
      if (windowWidth > 750) {
        this.banners = res.data.community;
      } else {
        this.banners = []
      }
    });
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "Forum",
      "relationId": this.$route.query.type
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    });
  },
  methods: {
    // !剑鸣路径
    keenMind(){
      this.$router.push('/Home/keenMind')
    },
    initial() {
      this.getProduct();
      this.getAnnounce();
      if (this.$store.state.token) {
        this.getSignInfo();
        this.getSigned();
      }
    },
    toAgreeMent() {
      const { href } = this.$router.resolve({
        name: "AgreeMent",
      });
      window.open(href, "_blank");
    },
    // !产品展开
    IsNones() {
      this.isNone = !this.isNone;
    },
    // !左侧导航筛选
    navTab(value, index) {
      this.loading = true;
      this.isNone = false;
      this.type = value;
      this.$router.push('/Home/Forum?type='+ value);
      this.typeClick(index);
    },
    // !右侧切换
    toSec(href) {
      if(this.$store.state.token){
        this.$router.push(href);
      } else {
        this.$toast({
          message: "请登录后查看!",
          duration: 1000,
        });
      }
    },
    // !分享新浪微博
    toSina(title, id) {
      this.shareNums(id);
      var url = ApiToken.shareUrl + "Home/forumDetails?id=" + id;
      url = encodeURIComponent(url);
      var a = screen.width / 2 - 300,
        d = screen.height / 2 - 175;
      window.open(
        "http://v.t.sina.com.cn/share/share.php?title=" + title + "&url=" + url,
        "Ignite",
        "status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=0,width=800,height=530,top=" +
          d +
          ",left=" +
          a
      );
    },
    // !草料二维码
    qrcode(link, id) {
      new QRCode(id, {
        text: link + '&code='+ Math.random(),
        width: 110,
        height: 110,
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    // !复制链接
    onCopy(e) {
      this.shareNums(e.text.split("id=")[1]);
      this.$toast({ message: "复制成功!", duration: 1000 });
    },
    // !移动端 是否已签到小窗口
    getSigned() {
      forum.getIsSign().then((res) => {
        if (res.status == 1) {
          this.isSigned = res.data;
        }
      });
    },
    // !签到 验证是否登录
    signShow() {
      if(this.$store.state.token){
        this.isSign = true;
        this.current = 0;
      } else {
        this.$toast({
          message: "请登录后操作!",
          duration: 1000,
        });
      }
    },
    signHide() {
      this.isSign = false;
      this.current = 1;
    },
    // !热榜 分页
    rankPageClick(page) {
      this.loading = true;
      this.rankParams.page = page;
      this.getRank();
    },
    // !实时 分页
    realPageClick(page) {
      this.loading = true;
      this.realParams.page = page;
      this.getReal();
    },
    // !左侧筛选
    typeClick(index) {
      this.rankParams.page = 1;
      this.realParams.page = 1;
      this.rankParams.productType = this.productList[index].value;
      this.realParams.productType = this.productList[index].value;
      if (!this.isPc) {
        this.isInitial = true;
        this.rankList = [];
        this.realList = [];
      }
      this.getRank();
      this.getReal();
    },
    // !热榜、实时榜 切换
    tabClick(index) {
      this.Ismust = false;
      this.loading = true;
      this.tab_current = index;
      this.isInitial = true;
      if (index == 0) {
        this.rankParams.page = 1;
        this.getRank();
      } else if (index == 1) {
        this.realParams.page = 1;
        this.getReal();
      }
    },
    search() {
      this.loading = true;
      this.rankParams.page = 1;
      this.realParams.page = 1;
      if (!this.isPc) {
        this.isInitial = true;
        this.rankList = [];
        this.realList = [];
      }
      this.getRank();
      this.getReal();
    },
    // !点击加载更多
    getList() {
      this.loading = true;
      this.Ismust = true;
      if (this.tab_current == 0) {
        this.rankParams.page++;
        this.getRank();
      } else {
        this.realParams.page++;
        this.getReal();
      }
    },
    // !获取左侧产品
    getProduct() {
      forum.getProduct().then((res) => {
        if (res.status == 1) {
          var data = []
          res.data.products.map(item => {
             if(item.extend2 == '' || item.extend2 == '驭剑'){
              data.push(item)
             }
          })
          this.productList.push(...data);
          this.productList_mobile.push(...data);
          if (this.$route.query.type) {
            this.productList.map((item, index) => {
              if (item.value == this.$route.query.type) {
                this.type = item.value;
                this.rankParams.productType = item.value;
                this.realParams.productType = item.value;
                this.getRank();
                this.getReal();
                this.isInitial = false;
              }
            });
          } else {
            this.type = this.productList[0].value
            this.rankParams.productType = this.productList[0].value;
            this.realParams.productType = this.productList[0].value;
            this.getRank();
            this.getReal();
            this.isInitial = false;
          }
        }
      });
    },
    // !热榜数据
    getRank() {
      this.rankParams.title = this.searchVal;
      if (!this.isPc) {
        forum.getHotApp(this.rankParams).then((res) => {
          if (res.status == 1) {
            if (res.data.length == 0) {
              this.noData = true;
            } else {
              this.noData = false;
            }
            res.data.map((item, index) => {
              if (item.industry != "") {
                item.industry = JSON.parse(item.industry);
                item.industryName = JSON.parse(item.industryName);
              } else {
                item.industry = [];
                item.industryName = [];
              }
              if (item.product != "") {
                item.product = JSON.parse(item.product);
                item.productName = JSON.parse(item.productName);
              } else {
                item.product = [];
                item.productName = [];
              }
              item.link = ApiToken.shareUrl + "Home/forumDetails?id=" + item.id;
            });
            if (this.Ismust) {
              this.rankList = this.rankList.concat(res.data);
            } else {
              this.rankList = res.data;
            }
            if (this.rankList.length < res.recordCount) {
              this.noRank = true;
            } else {
              this.noRank = false;
            }
            this.loading = false;
          } else {
            this.$toast(res.massage);
            this.loading = false;
          }
        });
      } else {
        forum.getHotPc(this.rankParams).then((res) => {
          if (res.status == 1) {
            if (res.data.length == 0) {
              this.noData = true;
            } else {
              this.noData = false;
            }
            Array.from(document.querySelectorAll(".qrCode_rank")).map((v) => {
              v.innerHTML = "";
            });
            res.data.map((item, index) => {
              if (item.industry != "") {
                item.industry = JSON.parse(item.industry);
                item.industryName = JSON.parse(item.industryName);
              } else {
                item.industry = [];
                item.industryName = [];
              }
              if (item.product != "") {
                item.product = JSON.parse(item.product);
                item.productName = JSON.parse(item.productName);
              } else {
                item.product = [];
                item.productName = [];
              }
              item.link = ApiToken.shareUrl + "Home/forumDetails?id=" + item.id;
            });
            this.rankList = res.data;
            const CodeDom = document.querySelector(".rank_content");
            if (CodeDom) {
              this.rankList.map((item, index) => {
                this.$nextTick(function () {
                  this.qrcode(item.link, "rankCode" + index);
                });
              });
            }
            this.rankRecordCount = res.recordCount;
            this.loading = false;
          } else {
            this.$toast(res.massage);
            this.loading = false;
          }
        });
      }
    },
    // !实时数据
    getReal() {
      this.realParams.title = this.searchVal;
      if (!this.isPc) {
        forum.getRealApp(this.realParams).then((res) => {
          if (res.status == 1) {
            res.data.map((item, index) => {
              if (item.industry != "") {
                item.industry = JSON.parse(item.industry);
                item.industryName = JSON.parse(item.industryName);
              } else {
                item.industry = [];
                item.industryName = [];
              }
              if (item.product != "") {
                item.product = JSON.parse(item.product);
                item.productName = JSON.parse(item.productName);
              } else {
                item.product = [];
                item.productName = [];
              }
              item.link = ApiToken.shareUrl + "Home/forumDetails?id=" + item.id;
            });
            if (this.Ismust) {
              this.realList = this.realList.concat(res.data);
            } else {
              this.realList = res.data;
            }
            if (this.realList.length < res.recordCount) {
              this.noReal = true;
            } else {
              this.noReal = false;
            }
            this.isInitial = false;
            this.realRecordCount = res.recordCount;
            this.loading = false;
          } else {
            this.$toast(res.message);
            this.loading = false;
          }
        });
      } else {
        forum.getRealPc(this.realParams).then((res) => {
          if (res.status == 1) {
            Array.from(document.querySelectorAll(".qrCode_real")).map((v) => {
              v.innerHTML = "";
            });
            res.data.map((item, index) => {
              if (item.industry != "") {
                item.industry = JSON.parse(item.industry);
                item.industryName = JSON.parse(item.industryName);
              } else {
                item.industry = [];
                item.industryName = [];
              }
              if (item.product != "") {
                item.product = JSON.parse(item.product);
                item.productName = JSON.parse(item.productName);
              } else {
                item.product = [];
                item.productName = [];
              }
              item.link = ApiToken.shareUrl + "Home/forumDetails?id=" + item.id;
            });
            const CodeDom = document.querySelector(".real_content");
            this.realList = res.data;
            if (CodeDom) {
              this.realList.map((item, index) => {
                this.$nextTick(function () {
                  this.qrcode(item.link, "realCode" + index);
                });
              });
            }
            this.realList = res.data;
            this.realRecordCount = res.recordCount;
            this.loading = false;
          } else {
            this.$toast(res.message);
            this.loading = false;
          }
        });
      }
    },
    // !去详情页
    toDetails(id) {
      this.$router.push("/Home/forumDetails?id=" + id);
    },
    // !收藏
    collect(type, id, isCollect, index) {
      if(!this.$store.state.token){
        this.$toast("请登录后操作!");
        return false;
      }
      debounce(() => {
        if (!isCollect) {
          forum.collectArticle({forumManagementId: id,}).then((res) => {
						if (res.status == 1) {
							if (res.message > 0) {
								this.$toast("收藏成功！ +" + res.message + " 积分");
							}else{
								this.$toast("收藏成功！")
							}
							if (this.tab_current == 0) {
								this.rankList[index].isCollect = true;
								this.rankList[index].collectCount++;
							} else {
								this.realList[index].isCollect = true;
								this.realList[index].collectCount++;
							}
						}else{
							this.$toast(res.message)
						}
					});
        } else {
          forum.collectCancelArticle({forumManagementId: id}).then((res) => {
						this.$toast({
							message: res.message,
							duration: 1000,
						});
						if (res.status == 1) {
							if (this.tab_current == 0) {
								this.rankList[index].isCollect = false;
								this.rankList[index].collectCount--;
							} else {
								this.realList[index].isCollect = false;
								this.realList[index].collectCount--;
							}
						}
					});
        }
      }, 1000);
    },
    // !点赞
    like(type, id, isLike, index) {
      if(!this.$store.state.token){
        this.$toast({
          message: "请登录后操作!",
        });
        return false;
      }
      debounce(() => {
        if (!isLike) {
          forum.likeArticle({ forumManagementId: id }).then((res) => {
            if (res.status == 1) {
              if (res.message > 0) {
                this.$toast("点赞成功！ +" + res.message + " 积分");
              } else {
                this.$toast("点赞成功！");
              }
              if (type == 0) {
                this.rankList[index].isLike = true;
                this.rankList[index].likeCount++;
                this.getRank();
              } else {
                this.realList[index].isLike = true;
                this.realList[index].likeCount++;
                this.getReal();
              }
            }
          });
        } else {
          forum.likeCancelArticle({ forumManagementId: id }).then((res) => {
            this.$toast({ message: res.message, duration: 1000 });
            if (res.status == 1) {
              if (type == 0) {
                this.rankList[index].isLike = false;
                this.rankList[index].likeCount--;
                this.getRank();
              } else {
                this.realList[index].isLike = false;
                this.realList[index].likeCount--;
                this.getReal();
              }
            }
          });
        }
      }, 1000);
    },
    getSignInfo() {
      forum.getSignInfo().then((res) => {
        if (res.status == 1) {
          this.signDate = res.data;
        }
      });
    },
    // !签到
    signClick() {
      if (!this.isSigned) {
        forum.signIn().then((res) => {
          if (res.status == 1) {
            this.isSigned = true;
            if (res.message > 0) {
              this.$toast("+ " + res.message + " 积分");
            }
            this.signDate++;
          } else {
            this.$toast(res.message);
          }
        });
      }
    },
    // !记录分享次数
    shareNums(id) {
      const Id = { forumManagementId: id };
      forum.shareNum(Id).then((res) => {});
    },
    // !公告
    getAnnounce() {
      forum.getAnnounce().then((res) => {
        if (res.status == 1) {
          this.announce = res.data;
          this.isNotice = true;
        }
      });
    },
    // !返回顶部
    scrollTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth < 768) {
        this.isPc = false;
      } else {
        this.isPc = true;
      }
    },
    $route: {
      handler(val, oldval) {
        this.loading = true;
        this.type = this.$route.query.type;
        this.rankParams.page = 1;
        this.realParams.page = 1;
        this.rankParams.productType = this.type;
        this.realParams.productType = this.type;
        this.getRank();
        this.getReal();
        
        // 数据统计
        const data ={
          "visitType": 1,
          "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
          "url": window.location.href,
          "pageUrl": "Forum",
          "relationId": this.$route.query.type
        }
        dataState(data).then(res => {
          sessionStorage.setItem('gid',res.data);
        });
      },
      // 深度观察监听
      deep: true,
    },
  },
  components: {
    Breadcrumb,
    swiper,
  },
};
</script>

<style>
@font-face {
  font-family: "iconfont";
  src: url("data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALgAAsAAAAABpAAAAKUAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCcAqBGIElATYCJAMICwYABCAFhG0HMRvMBcgekqQHVTBPbARB4wSAePj//b7tc98dE0mzpolnlTRIItK8kqBUSoISxLp6/BPX/AD9e6vzCoxeJGZTnrf4dDSczYazD9rAohfmdAjCZhJYTjEeISXI0hMJxcJcX8kpeZ/ncnoTKJD5gXJce/NRL8B4a4B7YZRIXEABcMP08gUOxH0C1fpRwM3OaOUKyfSyQNyQxTNXqBCVZVaoFMob9mbxSJnK9CBt8DD6fnw2FyGSsoJu2bnevnTV3lWbkuJ+6b6LgBAjoMNlKDALkImDxuwWr2A8WHXWZxhwrMbgXXWpFNjFUf88UU7X14JeKH0mmZM/an0CGY5PA2xMehtyQ0N5+a0MGr3cCV9ss8HzLf/Z9taPqYeI+A1dRDs9t+CaK77Lk1Uk1RyW51XlaJH5R9fM6uwtjv3n480f8MR9vvch36rWtmvpcKGWTJstyOl/ycJ/5+ZN6v1uS18tP28eD3fep0bXFU/Tf7HnXywnD06Si39DNRm8/WpxkTkb5qfyNL7gd8kGtmWh0IZ0XSnyJdO55RKqVaMEB5tdpxrb7s0dKgsyhqRCHwqVRsnMzqJMjXmUq7SCajO8wzVaTHhFrsK0DUBotEdS7wkKjc5kZi8o0+6Gco3hRbWjaDmzxnjIkQ3CM8ZLaN7C4MI0BOvgSNy4QNHRziiviPjbSGs5ATzDlQtdNJCW2DC9EiszFqBLpg4ueAw1zYQRmQpecKHPbFVZttv0okLL1F0RGwg6w9Al0HQLDFwwGYSgO4tUPr+ARA7NGerg5JLbEFmTJwc8BjeA7GqNQZxHeWbqiqhihglAFzHpgAtmIY3GBEbNsxToAivoT0hbqljTqTtUW9her//dHqimTSnM1ELFTXJ0C9nlAg==")
    format("woff2");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trumpet:before {
  content: "\e60a";
}
</style>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.rotate_180 {
  transform: rotate(180deg);
}

.forum_page {
  .merryArea_mobile {
    display: none;
  }

  .signPop_area {
    position: absolute;
    top: 540px;
    right: 50%;
    transform: translateX(50%);
    width: 810px;
    padding: 50px 0;
    background: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 100;

    i {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      cursor: pointer;

    }

    & > p {
      font-size: 28px;
      color: #323332;
      text-align: center;
      margin-bottom: 100px;

      span {
        font-size: 28px;
        font-weight: 700;
        color: #6b18a8;
      }
    }

    & > div {
      width: calc(100% - 120px);
      margin: 0 auto;

      & > div:first-of-type {
        background: #dac6e9;
        border-radius: 8px;
        width: 100%;
        height: 20px;
        position: relative;
        display: flex;
        align-items: center;

        & > div:first-of-type {
          width: 100%;
          height: 100%;
          background: url(../../assets/PC/forum/sign_img.png) center no-repeat;
          background-size: 100%;
          position: relative;

          div {
            position: absolute;
            height: 100%;
            width: 100%;
            right: 0;
            background: #dac6e9;
            border-radius: 8px;
          }
        }

        & > div:nth-of-type(2) {
          position: absolute;
          top: -20px;

          p {
            font-size: 18px;
            color: #fff;
            width: 290px;
            height: 37px;
            background: url(../../assets/PC/forum/sign_text_bac.png) center
              no-repeat;
            background-size: 100%;
            line-height: 33px;
            text-align: center;
            position: absolute;
            top: -37px;
            left: -125px;
          }

          img {
            width: 36px;
          }
        }
      }

      & > div:nth-of-type(2) {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding: 0 18px;

        p {
          font-size: 24px;
          color: #999999;
        }
      }
    }

    button {
      display: block;
      width: 156px;
      height: 36px;
      font-size: 16px;
      line-height: 36px;
      background: #6b18a8;
      color: #fff;
      text-align: center;
      border: 0;
      margin: 40px auto 24px;
      cursor: pointer;

      &.unClickable {
        background: #ccc;
      }
    }

    a {
      display: block;
      font-size: 16px;
      color: #9767b8;
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
    }
  }

  .typeArea {
    position: relative;

    .crumb {
      margin: 30px 0;
    }

    .content {
      display: flex;
      justify-content: space-between;

      .left {
        width: 150px;

        h2 {
          font-size: 24px;
          color: #363d42;
          line-hieght: 40px;
          margin: 0 0 24px 0;
        }

        ul {
          background: #f6f8fa;

          li {
            font-size: 16px;
            height: 60px;
            line-height: 60px;
            color: #9eabba;
            text-align: center;
            cursor: pointer;

            &:hover {
              color: #6b18a8;
              font-weight: 700;
            }

            &.current {
              color: #6b18a8;
              font-weight: 700;
            }
          }
        }
      }

      .center {
        width: 720px;

        .merry_area {
          background: #f6f8fa;
          width: 100%;
          height: 34px;
          line-height: 34px;
          margin-bottom: 20px;

          svg {
            width: 21px;
            min-width: 21px;
            height: 19px;
            color: #9eabba;
            margin: 0 8px 0 12px;
          }

          span {
            color: #738294;
            font-size: 16px;
            height: 100%;
            overflow: auto;

            &::-webkit-scrollbar {
              display: none;
            }
          }
        }

        .search_area,
        .operate_list,
        .module_list {
          display: none;
        }

        .tab_nav {
          display: flex;
          border-bottom: 1px solid #beccd6;

          .nav_item {
            width: 60px;
            font-size: 16px;
            color: #738294;
            line-height: 32px;
            margin: 0 40px 0 0;
            text-align: center;
            cursor: pointer;

            &.active {
              font-size: 18px;
              color: #6b18a8;
              font-weight: 700;
              background: none;
            }

            &.active:after {
              content: "";
              display: block;
              width: 100%;
              height: 2px;
              position: relative;
              bottom: -1px;
              background: #6b18a8;
            }
          }
        }

        .tab_content {
          .tab_item {
            .rank_content {
              padding: 40px 0 0 0;
              margin-bottom: 20px;

              .rank_item {
                background: #fff;
                border-bottom: 1px solid #e6e6e6;
                position: relative;
                padding: 40px;

                .rank_icon {
                  position: absolute;
                  top: -14px;
                  left: -10px;

                  &.rank_icon_inner {
                    top: 0;
                    left: 0;
                  }
                }

                .title_area {
                  display: flex;
                  justify-content: space-between;

                  h4 {
                    font-size: 16px;
                    color: #363d42;
                    cursor: pointer;
                  }

                  svg {
                    display: none;
                    width: 21px;
                    height: 21px;
                  }
                }

                .item_date {
                  font-size: 16px;
                  color: #738294;
                  margin: 12px 0;
                }

                .item_desc {
                  font-size: 16px;
                  color: #738294;
                  line-height: 26px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  cursor: pointer;
                }

                .item_kind {
                  margin: 20px 0 30px 0;
                  display: flex;
                  flex-wrap: wrap;
                  a {
                    display: block;
                    padding: 0 8px;
                    min-width: 34px;
                    border: 1px solid #e6e6e6;
                    height: 26px;
                    line-height: 26px;
                    font-size: 16px;
                    color: #6b18a8;
                    text-align: center;
                    font-weight: 700;
                    margin: 0 5px 5px 0;
                  }
                }

                .item_end {
                  display: flex;
                  justify-content: space-between;

                  .item_num {
                    display: flex;

                    & > a {
                      display: flex;
                      align-items: center;
                      margin-right: 28px;
                      position: relative;

                      span {
                        font-size: 16px;
                        color: #363d42;
                        margin-left: 8px;
                      }

                      svg {
                        transition: all 0.5s;
                      }

                      svg:first-of-type {
                        opacity: 1;
                      }

                      svg:nth-of-type(2) {
                        position: absolute;
                        left: 0;
                        opacity: 0;
                      }

                      &:first-of-type {
                        cursor: pointer;

                        svg {
                          width: 17px;
                          height: 17px;
                        }
                      }

                      &:nth-of-type(2) {
                        cursor: pointer;

                        svg {
                          width: 17px;
                          height: 21px;
                        }
                      }

                      &:nth-of-type(3) {
                        svg {
                          width: 18px;
                          height: 18px;
                        }
                      }

                      &:nth-of-type(4) {
                        cursor: pointer;
                        position: relative;

                        svg {
                          width: 18px;
                          height: 18px;
                        }

                        &:hover ul {
                          display: block;
                        }

                        ul {
                          display: none;
                          width: 156px;
                          height: 295px;
                          background: url(../../assets/PC/forum/share_bac.png)
                            center no-repeat;
                          background-size: 100%;
                          padding: 20px 0 0 0;
                          position: absolute;
                          top: 30px;
                          left: -70px;
                          z-index: 5;

                          li {
                            width: 100%;
                            line-height: 36px;
                            margin-top: 8px;
                            display: flex;
                            padding-left: 22px;
                            align-items: center;

                            span {
                              font-size: 16px;
                              color: #6c7179;
                              margin-left: 6px;
                            }

                            &:first-of-type {
                              img {
                                width: 20px;
                              }
                            }

                            &:nth-of-type(2) {
                              img {
                                width: 22px;
                              }
                            }

                            &:nth-of-type(3) {
                              img {
                                width: 22px;
                              }
                            }

                            &:nth-of-type(4) {
                              justify-content: center;
                              padding: 0;

                              img {
                                width: 104px;
                              }
                            }

                            &:nth-of-type(5) {
                              display: none;
                            }
                          }
                        }
                      }

                      &.active {
                        border: 0;
                        background: none;

                        svg:first-of-type {
                          opacity: 0;
                        }

                        svg:nth-of-type(2) {
                          opacity: 1;
                        }
                      }
                    }
                  }

                  .router_icon {
                    width: 31px;
                    height: 38px;
                    position: relative;
                    cursor: pointer;

                    img {
                      opacity: 0;
                      transition: all 0.5s;
                      position: absolute;
                    }

                    i {
                      font-size: 15px;
                      font-weight: 700;
                      transition: all 0.5s;
                      margin: 12px 0 0 8px;
                    }
                  }
                }

                &:hover {
                  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
                  z-index: 2;

                  .router_icon {
                    img {
                      opacity: 1;
                    }

                    i {
                      color: white;
                      transform: rotate(-45deg);
                    }
                  }
                }

                &.rank_item_top {
                  background: #f6f8fa;
                  border-top: 1px solid #e6e6e6;
                }
              }

              .noData {
                font-size: 24px;
                color: #ccc;
                text-align: center;
                font-weight: 700;

                img {
                  width: 200px;
                }
              }

              .rank_pagination {
                margin: 60px 0 100px 0;

                .el-pagination {
                  display: flex;
                  justify-content: flex-end;

                  /deep/ .el-pager li {
                    width: 45px;
                    height: 45px;
                    font-size: 16px;
                    color: #363d42;
                    text-align: center;
                    line-height: 45px;
                  }

                  /deep/ .el-pager li.active {
                    color: #fff;
                    background-color: #6b18a8;
                  }

                  /deep/ .btn-next {
                    width: 95px;
                    height: 45px;
                    color: #fff;
                    background-color: #6b18a8;
                  }
                }
              }
            }

            .rank_load {
              display: none;
            }

            .real_content {
              padding: 40px 0 0 0;
              margin-bottom: 20px;
              .real_item {
                border-bottom: 1px solid #e6e6e6;
                position: relative;
                padding: 40px;
                background: #fff;

                .real_icon {
                  position: absolute;
                  top: 0;
                  left: 0;

                  &.real_icon_inner {
                    top: 0;
                    left: 0;
                  }
                }

                .title_area {
                  display: flex;
                  justify-content: space-between;

                  h4 {
                    font-size: 16px;
                    color: #363d42;
                    cursor: pointer;
                  }

                  svg {
                    display: none;
                    width: 21px;
                    height: 21px;
                  }
                }

                .item_date {
                  font-size: 16px;
                  color: #738294;
                  margin: 12px 0;
                }

                .item_desc {
                  font-size: 16px;
                  color: #738294;
                  line-height: 26px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  cursor: pointer;
                }

                .item_kind {
                  margin: 20px 0 30px 0;
                  display: flex;
                  flex-wrap: wrap;
                  a {
                    display: block;
                    padding: 0 8px;
                    min-width: 34px;
                    border: 1px solid #e6e6e6;
                    height: 26px;
                    line-height: 26px;
                    font-size: 16px;
                    color: #6b18a8;
                    text-align: center;
                    font-weight: 700;
                    margin: 0 5px 5px 0;
                  }
                }

                .item_end {
                  display: flex;
                  justify-content: space-between;

                  .item_num {
                    display: flex;

                    & > a {
                      display: flex;
                      align-items: center;
                      margin-right: 28px;
                      position: relative;

                      span {
                        font-size: 16px;
                        color: #363d42;
                        margin-left: 8px;
                      }

                      svg {
                        transition: all 0.5s;
                      }

                      svg:first-of-type {
                        opacity: 1;
                      }

                      svg:nth-of-type(2) {
                        position: absolute;
                        left: 0;
                        opacity: 0;
                      }

                      &:first-of-type {
                        cursor: pointer;

                        svg {
                          width: 17px;
                          height: 17px;
                        }
                      }

                      &:nth-of-type(2) {
                        cursor: pointer;

                        svg {
                          width: 17px;
                          height: 21px;
                        }
                      }

                      &:nth-of-type(3) {
                        svg {
                          width: 18px;
                          height: 18px;
                        }
                      }

                      &:nth-of-type(4) {
                        cursor: pointer;
                        position: relative;

                        svg {
                          width: 18px;
                          height: 18px;
                        }

                        &:hover ul {
                          display: block;
                        }

                        ul {
                          display: none;
                          width: 156px;
                          height: 295px;
                          background: url(../../assets/PC/forum/share_bac.png)
                            center no-repeat;
                          background-size: 100%;
                          padding: 20px 0 0 0;
                          position: absolute;
                          top: 30px;
                          left: -70px;
                          z-index: 5;

                          li {
                            width: 100%;
                            line-height: 36px;
                            margin-top: 8px;
                            display: flex;
                            padding-left: 22px;
                            align-items: center;

                            span {
                              font-size: 16px;
                              color: #6c7179;
                              margin-left: 6px;
                            }

                            &:first-of-type {
                              img {
                                width: 20px;
                              }
                            }

                            &:nth-of-type(2) {
                              img {
                                width: 22px;
                              }
                            }

                            &:nth-of-type(3) {
                              img {
                                width: 22px;
                              }
                            }

                            &:nth-of-type(4) {
                              justify-content: center;
                              padding: 0;
                            }

                            &:nth-of-type(5) {
                              display: none;
                            }
                          }
                        }
                      }

                      &.active {
                        border: 0;
                        background: none;

                        svg:first-of-type {
                          opacity: 0;
                        }

                        svg:nth-of-type(2) {
                          opacity: 1;
                        }
                      }
                    }
                  }

                  .router_icon {
                    width: 31px;
                    height: 38px;
                    position: relative;
                    cursor: pointer;

                    img {
                      opacity: 0;
                      transition: all 0.5s;
                      position: absolute;
                    }

                    i {
                      font-size: 15px;
                      font-weight: 700;
                      transition: all 0.5s;
                      margin: 12px 0 0 8px;
                    }
                  }
                }

                &:hover {
                  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
                  z-index: 2;

                  .router_icon {
                    img {
                      opacity: 1;
                    }

                    i {
                      color: white;
                      transform: rotate(-45deg);
                    }
                  }
                }

                &.real_item_top {
                  background: #f6f8fa;
                  border-top: 1px solid #e6e6e6;
                }
              }

              .noData {
                font-size: 24px;
                color: #ccc;
                text-align: center;
                font-weight: 700;

                img {
                  width: 200px;
                }
              }

              .real_pagination {
                margin: 60px 0 100px 0;

                .el-pagination {
                  display: flex;
                  justify-content: flex-end;

                  /deep/ .el-pager li {
                    width: 45px;
                    height: 45px;
                    font-size: 16px;
                    color: #363d42;
                    text-align: center;
                    line-height: 45px;
                  }

                  /deep/ .el-pager li.active {
                    color: #fff;
                    background-color: #6b18a8;
                  }

                  /deep/ .btn-next {
                    width: 95px;
                    height: 45px;
                    color: #fff;
                    background-color: #6b18a8;
                  }
                }
              }
            }

            .rank_load {
              display: none;
            }
          }
        }
      }

      .right {
        width: 260px;

        .search_area {
          width: 100%;
          height: 34px;
          border: 4px solid #6b18a8;
          padding: 0 20px;
          display: flex;
          align-items: center;

          svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            cursor: pointer;
          }

          input {
            font-size: 14px;
            line-height: 23px;
            width: 100%;
            border: 0;
          }
        }

        .operate_list {
          li {
            width: 100%;
            height: 100px;
            padding: 24px 0 0 0;
            margin: 2px 0 0 0;
            background: #f6f8fa;
            text-align: center;
            cursor: pointer;

            p {
              font-size: 16px;
              color: #545c69;
              margin-top: 8px;
            }

            svg:first-of-type {
              display: inline;
            }

            svg:nth-of-type(2) {
              display: none;
            }

            &:first-of-type {
              svg {
                width: 28px;
                height: 26px;
              }
            }

            &:nth-of-type(2) {
              svg {
                width: 30px;
                height: 26px;
              }
            }

            &:nth-of-type(3) {
              svg {
                width: 25px;
                height: 29px;
              }
            }

            &:nth-of-type(4) {
              svg {
                width: 26px;
                height: 22px;
              }
            }

            &:nth-of-type(5) {
              svg {
                width: 25px;
                height: 29px;
              }
            }

            &:nth-of-type(6) {
              svg {
                width: 26px;
                height: 26px;
              }
            }

            &:hover {
              background: #6b18a8;
              box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);

              p {
                color: #fff;
              }

              svg {
                color: white;
              }

              svg:first-of-type {
                display: none;
              }

              svg:nth-of-type(2) {
                display: inline;
              }
            }

            &.current {
              background: #6b18a8;
              box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);

              p {
                color: #fff;
              }

              svg {
                color: white;
              }

              svg:first-of-type {
                display: none;
              }

              svg:nth-of-type(2) {
                display: inline;
              }
            }
          }
        }

        .adver_area {
          width: 100%;
          height: 260px;
          background: url(../../assets/PC/forum/announce_bac.png) center
            no-repeat;
          background-size: 100%;
          position: relative;
          padding: 50px 0 0 22px;

          svg {
            width: 23px;
            height: 23px;
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
          }

          p:first-of-type {
            font-size: 24px;
            font-weight: 700;
            color: #fff;
          }

          div {
            width: 128px;
            height: 3px;
            margin: 16px 0;
            background-image: linear-gradient(to right, #ffbc00, #f70756);
          }

          p:nth-of-type(2) {
            font-size: 16px;
            color: #fff;
          }
        }

        .end_btn {
          display: flex;
          justify-content: space-between;
          margin: 24px 0 10px 0;

          a {
            font-size: 16px;
            color: #6b18a8;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .forum_page {
    .merryArea_mobile {
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      display: block;
    }

    .search_input a img {
      width: vw(40);
      height: vw(40);
    }

    .rank_item_top:last-of-type {
      margin-bottom: vw(30);
    }

    .praise0 {
      width: 17px;
      height: 17px;
    }
  }

  .forum_page {
    .sign_btn {
      width: vw(129);
      height: vw(108);
      position: fixed;
      top: 50%;
      left: 0;
      background: #6b18a8;
      border-radius: 0 vw(54) vw(54) 0;
      padding: vw(20) 0 0 0;
      text-align: center;
      z-index: 101;

      svg {
        color: #fff;
        width: vw(40);
        height: vw(37);
        margin-bottom: vw(5);
      }

      p {
        width: 100%;
        text-align: center;
        font-size: vw(24);
        color: #fff;
        margin: 0;
      }
    }

    .pop_mask {
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      z-index: 97;
    }

    .signPopArea_mobile {
      position: absolute;
      top: vw(240);
      right: vw(30);
      width: vw(690);
      padding: vw(80) 0;
      background: #fff;
      box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, 0.2);
      border-radius: vw(8);
      z-index: 2;

      i {
        position: absolute;
        top: vw(-100);
        right: 0;
        font-size: vw(56);
        color: #fff;
        cursor: pointer;
      }

      & > p {
        font-size: vw(32);
        color: #323332;
        text-align: center;
        margin-bottom: vw(144);

        span {
          font-weight: 700;
          color: #6b18a8;
        }
      }

      & > div {
        width: vw(620);
        margin: 0 auto;

        & > div:first-of-type {
          background: #dac6e9;
          border-radius: vw(8);
          width: vw(600);
          margin: 0 auto;
          height: vw(20);
          position: relative;
          display: flex;
          align-items: center;

          & > div:first-of-type {
            width: 100%;
            height: 100%;
            background: url(../../assets/PC/forum/sign_img.png) center no-repeat;
            background-size: 100%;
            position: relative;

            div {
              position: absolute;
              height: 100%;
              width: 100%;
              right: 0;
              background: #dac6e9;
              border-radius: vw(8);
            }
          }

          & > div:nth-of-type(2) {
            position: absolute;
            top: vw(-20);

            p {
              font-size: vw(20);
              color: #fff;
              width: vw(206);
              height: vw(87);
              background: url(../../assets/PC/forum/sign_text_bac_mobile.png)
                center no-repeat;
              background-size: 100%;
              padding-top: vw(6);
              line-height: vw(36);
              text-align: center;
              position: absolute;
              top: vw(-87);
              left: vw(-83);
            }

            img {
              width: vw(36);
              cursor: pointer;
            }
          }
        }

        & > div:nth-of-type(2) {
          margin-top: vw(20);
          display: flex;
          justify-content: space-between;
          padding: 0 vw(10);

          p {
            font-size: vw(24);
            color: #999999;
          }
        }
      }

      button {
        display: block;
        width: vw(156);
        height: vw(56);
        line-height: vw(56);
        font-size: vw(24);
        background: #6b18a8;
        color: #fff;
        text-align: center;
        border: 0;
        margin: vw(56) auto vw(24);
        cursor: pointer;

        &.unClickable {
          background: #ccc;
        }
      }

      a {
        display: block;
        font-size: vw(20);
        color: #9767b8;
        text-decoration: underline;
        text-align: center;
        cursor: pointer;
      }
    }

    .typeArea {
      .crumb {
        display: none;
        margin: vw(55) 0 vw(40);
      }

      .content {
        .left,
        .right {
          display: none;
        }

        .center {
          width: 100%;
          padding: 0 0 vw(20) 0;

          .merry_area {
            display: none;
            height: vw(52);
            line-height: vw(52);
            margin-bottom: vw(58);

            svg {
              width: vw(31);
              height: vw(28);
              margin: 0 vw(15) 0 vw(19);
            }

            span {
              font-size: vw(20);
            }
          }

          .search_area,
          .operate_list,
          .module_list {
            display: block;
          }

          .search_area {
            width: 100%;
            height: vw(70);
            margin: vw(35) 0 vw(18);
            display: flex;
            justify-content: space-between;
            align-items: center;

            .search_input {
              width: vw(600);
              height: vw(70);
              border: vw(5) solid #6b18a8;
              border-radius: vw(16);
              padding: 0 vw(40);
              display: flex;
              align-items: center;

              svg {
                width: vw(39);
                height: vw(39);
                margin-right: vw(12);
              }

              input {
                font-size: vw(24);
                line-height: vw(48);
                width: 100%;
                border: 0;
              }
            }

            .caret_icon {
              text-align: center;
              cursor: pointer;

              i,
              span {
                display: block;
              }

              i {
                color: #6b18a8;
              }

              span {
                font-size: vw(24);
                color: #738294;
              }
            }
          }

          .operate_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, 0.2);

            li {
              width: vw(225);
              height: vw(120);
              padding: vw(20) 0 0 0;
              margin: 0 0 vw(2) 0;
              border-right: vw(1) solid #e4e4e4;
              background: #f6f8fa;
              text-align: center;
              cursor: pointer;

              p {
                font-size: vw(24);
                color: #545c69;
                margin-top: vw(13);
              }

              svg:first-of-type {
                display: inline;
              }

              svg:nth-of-type(2) {
                display: none;
              }

              &:first-of-type {
                display: none;

                svg {
                  width: vw(28);
                  height: vw(26);
                }
              }

              &:nth-of-type(2) {
                border-radius: vw(8) 0 0 vw(8);

                svg {
                  width: vw(30);
                  height: vw(26);
                }
              }

              &:nth-of-type(3) {
                display: none;

                svg {
                  width: vw(25);
                  height: vw(29);
                }
              }

              &:nth-of-type(4) {
                display: none;

                svg {
                  width: vw(26);
                  height: vw(22);
                }
              }

              &:nth-of-type(5) {
                svg {
                  width: vw(24);
                  height: vw(28);
                }
              }

              &:nth-of-type(6) {
                border: 0;
                border-radius: 0 vw(8) vw(8) 0;

                svg {
                  width: vw(26);
                  height: vw(25);
                }
              }

              &:hover {
                background: #6b18a8;
                box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, 0.5);

                p {
                  color: #fff;
                }

                svg {
                  color: white;
                }

                svg:first-of-type {
                  display: none;
                }

                svg:nth-of-type(2) {
                  display: inline;
                }
              }

              &.current {
                background: #6b18a8;
                box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, 0.5);

                p {
                  color: #fff;
                }

                svg {
                  color: white;
                }

                svg:first-of-type {
                  display: none;
                }

                svg:nth-of-type(2) {
                  display: inline;
                }
              }
            }
          }

          .module_list {
            width: 100%;
            margin: vw(42) 0;

            .nav0-item {
              position: relative;
              width: 50%;
              height: vw(60);
              line-height: vw(60);
              border-bottom: 1px #ccc solid;
            }

            .nav0-item-pop {
              background: #f6f6f6;
              position: absolute;
              left: 0;
              top: 30px;
              width: 100%;
              z-index: 9999;
            }
          }

          .tab_nav {
            border-bottom: vw(1) solid #beccd6;

            .nav_item {
              width: vw(150);
              font-size: vw(28);
              line-height: vw(68);
              margin: 0 vw(100) 0 0;

              &.active {
                border: 0;
                font-size: vw(28);
              }

              &.active:after {
                width: 100%;
                height: vw(3);
                bottom: vw(-1);
              }
            }
          }

          .tab_content {
            .tab_item {
              .rank_content {
                padding: vw(40) 0 0 0;

                .rank_item {
                  padding: vw(40);

                  .rank_icon {
                    width: vw(75);
                    top: vw(-16);
                    left: vw(-10);

                    &.rank_icon_inner {
                      width: vw(85);
                    }
                  }

                  .title_area {
                    -webkit-text-size-adjust: none;

                    h4 {
                      font-size: vw(28);
                      -webkit-text-size-adjust: none;
                    }

                    svg {
                      display: none;
                      width: 21px;
                      height: 21px;
                    }
                  }

                  .item_date {
                    font-size: vw(20);
                    margin: vw(15) 0 vw(24);
                  }

                  .item_desc {
                    font-size: vw(24)!important;
                    line-height: vw(36);

                    .violet {
                      font-size: vw(24) !important;
                    }
                  }

                  .item_kind {
                    flex-wrap: wrap;
                    margin: vw(30) 0 vw(30) 0;

                    a {
                      padding: 0 vw(22);
                      min-width: vw(94);
                      border: vw(1) solid #e6e6e6;
                      height: vw(50);
                      line-height: vw(50);
                      font-size: vw(24);
                      margin: 0 vw(12) vw(10) 0;
                    }
                  }

                  .item_end {
                    display: block;

                    .item_num {
                      & > a {
                        margin-right: vw(56);

                        span {
                          font-size: vw(24);
                          margin-left: vw(11);
                        }

                        &:first-of-type {
                          svg {
                            width: vw(25);
                            height: vw(25);
                          }
                        }

                        &:nth-of-type(2) {
                          svg {
                            width: vw(30);
                            min-width: vw(30);
                            height: vw(36);
                          }
                        }

                        &:nth-of-type(3) {
                          svg {
                            width: vw(28);
                            height: vw(29);
                          }
                        }

                        &:nth-of-type(4) {
                          svg {
                            width: vw(28);
                            height: vw(29);
                          }
                        }

                        &:nth-of-type(4) {
                          svg {
                            width: vw(27);
                            height: vw(28);
                          }

                          ul {
                            width: vw(298);
                            height: vw(220);
                            background: #ffffff;
                            box-shadow: 0px 4px 9px 1px rgba(96, 96, 96, 0.4);
                            border-radius: 8px;
                            padding: 0;
                            top: vw(50);
                            left: vw(-120);

                            li {
                              line-height: vw(110);
                              margin-top: 0;
                              display: flex;
                              padding-left: 0;
                              justify-content: center;
                              align-items: center;

                              span {
                                font-size: vw(24);
                                margin-left: vw(24);
                              }

                              &:first-of-type {
                                img {
                                  width: vw(40);
                                }

                                &:hover + li + li + li + li {
                                  i {
                                    color: #efe7f6;
                                  }
                                }
                              }

                              &:nth-of-type(2) {
                                img {
                                  width: vw(54);
                                }
                              }

                              &:nth-of-type(3) {
                                display: none;
                              }

                              &:nth-of-type(4) {
                                display: none;
                              }

                              &:nth-of-type(5) {
                                display: block;
                                line-height: vw(16);
                                position: absolute;
                                top: vw(-16);
                                left: 50%;
                                transform: translateX(vw(-16));

                                i {
                                  font-size: vw(16);
                                  color: #fff;
                                }
                              }

                              &:hover {
                                background: #efe7f6;
                              }
                            }
                          }
                        }
                      }
                    }

                    .router_icon {
                      display: none;
                      width: vw(55);
                      height: vw(69);
                      margin-top: vw(40);

                      svg {
                        width: vw(23);
                        height: vw(23);
                        transform: rotate(90deg);
                      }
                    }
                  }

                  &:first-of-type {
                    // border: 0 !important;
                    // margin-bottom: vw(38);
                  }

                  &:hover {
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
                    z-index: 2;

                    .router_icon {
                      display: none;
                    }
                  }

                  &.rank_item_top {
                    border-top: vw(1) solid #e6e6e6;

                    &:last-of-type {
                      margin-bottom: vw(38);
                    }
                  }
                }

                .noData {
                  font-size: vw(32);
                }

                .rank_pagination {
                  display: none;
                }
              }

              .rank_load {
                display: block;
                text-align: center;
              }

              .real_content {
                padding: vw(40) 0 0 0;
                margin-bottom: 20px;
                .real_item {
                  // border-top: vw(1) solid #e6e6e6;
                  // border-bottom: 0;
                  padding: vw(40);

                  .real_icon {
                    width: vw(75);
                    top: vw(-16);
                    left: vw(-10);

                    &.real_icon_inner {
                      width: vw(85);
                    }
                  }

                  .title_area {
                    -webkit-text-size-adjust: none;

                    h4 {
                      font-size: vw(28);
                      -webkit-text-size-adjust: none;
                    }

                    svg {
                      display: none;
                      width: 21px;
                      height: 21px;
                    }
                  }

                  .item_date {
                    font-size: vw(20);
                    margin: vw(15) 0 vw(24);
                  }

                  .item_desc {
                    font-size: vw(24);
                    line-height: vw(36);
                    .violet {
                      font-size: vw(24) !important;
                    }
                  }

                  .item_kind {
                    flex-wrap: wrap;
                    margin: vw(30) 0 vw(30) 0;

                    a {
                      padding: 0 vw(22);
                      min-width: vw(94);
                      border: vw(1) solid #e6e6e6;
                      height: vw(50);
                      line-height: vw(50);
                      font-size: vw(24);
                      margin: 0 vw(12) vw(10) 0;
                    }
                  }

                  .item_end {
                    display: block;

                    .item_num {
                      & > a {
                        margin-right: vw(56);

                        span {
                          font-size: vw(24);
                          margin-left: vw(11);
                        }

                        &:first-of-type {
                          svg {
                            width: vw(25);
                            height: vw(25);
                          }
                        }

                        &:nth-of-type(2) {
                          svg {
                            width: vw(28);
                            min-width: vw(28);
                            height: vw(36);
                          }
                        }

                        &:nth-of-type(3) {
                          svg {
                            width: vw(28);
                            height: vw(29);
                          }
                        }

                        &:nth-of-type(4) {
                          svg {
                            width: vw(27);
                            height: vw(28);
                          }

                          ul {
                            width: vw(298);
                            height: vw(220);
                            background: #ffffff;
                            box-shadow: 0px 4px 9px 1px rgba(96, 96, 96, 0.4);
                            border-radius: 8px;
                            padding: 0;
                            top: vw(50);
                            left: vw(-120);

                            li {
                              line-height: vw(110);
                              margin-top: 0;
                              display: flex;
                              padding-left: 0;
                              justify-content: center;
                              align-items: center;

                              span {
                                font-size: vw(24);
                                margin-left: vw(24);
                              }

                              &:first-of-type {
                                img {
                                  width: vw(40);
                                }

                                &:hover + li + li + li + li {
                                  i {
                                    color: #efe7f6;
                                  }
                                }
                              }

                              &:nth-of-type(2) {
                                img {
                                  width: vw(54);
                                }
                              }

                              &:nth-of-type(3) {
                                display: none;
                              }

                              &:nth-of-type(4) {
                                display: none;
                              }

                              &:nth-of-type(5) {
                                display: block;
                                line-height: vw(16);
                                position: absolute;
                                top: vw(-16);
                                left: 50%;
                                transform: translateX(vw(-16));

                                i {
                                  font-size: vw(16);
                                  color: #fff;
                                }
                              }

                              &:hover {
                                background: #efe7f6;
                              }
                            }
                          }
                        }
                      }
                    }

                    .router_icon {
                      display: none;
                      width: vw(55);
                      height: vw(69);
                      margin-top: vw(40);

                      svg {
                        width: vw(23);
                        height: vw(23);
                        transform: rotate(90deg);
                      }
                    }
                  }

                  &:hover {
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
                    z-index: 99;

                    .router_icon {
                      display: none;
                    }
                  }

                  &.real_item_top {
                    border-top: vw(1) solid #e6e6e6;
                  }
                }

                .noData {
                  font-size: vw(32);
                }

                .real_pagination {
                  display: none;
                }
              }

              .rank_load {
                display: block;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .van-notice-bar {height: 34px; line-height: 34px; background: inherit;}
  .van-notice-bar, .van-notice-bar__content {color:#6b18a8}

  @media (max-width: 750px) {
    .van-notice-bar {height: vw(56); line-height: vw(68); background: inherit;}
  }
</style>
